@charset "utf-8";
/* CSS Document */

*{ margin:0; padding:0;}
img{border:0; z-index:1;}
html,body{
    width:100%;
    height:100%;
    font-family: "微软雅黑","宋体",Arial,Helvetica,sans-serif;
    font-size: 14px;
    color: #333;
    margin: 0;
    padding: 0; }
ul,li,ol,dl,dt,dd{list-style-type:none;}
a,li{text-decoration:none;}
p,span,h1,h2,h3,h4,h5,h6{color:#666666; } */
.fr{ float:right}
.fl{ float:left}

/*登录*/
.login_bg{
    width: 100%;
    height: 100%;
    display: flex;
    background-repeat: no-repeat;
    background-image: url('assets/lg_bg.jpg');
    background-size: 100% 100%;
}
.login_content{ 
    width: 835px;
    height: 430px;
    border-radius: 10px;
    box-shadow: 0px 10px 15px #ccc;
    background-color: #fff;
    margin: auto;
    margin-top: 30px;
}
.login_left{width:475px;height:430px;}
.login_right{width:360px;height:430px;}
.lgr_box{
    width:240px;
    margin:0 auto;
    margin-top:90px;
    font-size:14px;
}
.login_right h2{
    letter-spacing: 10px;
    margin-bottom: 20px;
    display: block;
    text-align: center;
    color: #666666;
    font-size: 18px;}
.login_biaoti{ margin:19px 0 20px 32px;}
.login_item{ 
    position: relative;
    margin-bottom:20px; 
    }
.lg_icon{
    height:16px;
    position:absolute;
    left:0px;
    top:10px;
    
}
.login_input{
     width:218px; 
     height:38px; 
     border:0;
     outline: none;
     border-bottom:1px solid #bbbbbb; 
     padding-left:22px;  
     vertical-align:middle; 
     font-size:14px;
      color:#999999;}
.login_btn{ 
    width:240px; 
    height:30px; 
    line-height:30px; 
    border-radius: 3px;
    font-size:14px;
    border: none;
    text-align:center; 
    background:#007fdd; 
    color:#ffffff;
    margin:auto; 
    margin-bottom:10px;
    letter-spacing: 10px; 
    outline:none;
    cursor:pointer;}
.login_item2{ 
    width:240px; 
    height:30px; 
    margin:auto; 
    text-align:left; 
}
.login_item2 a{color:#416EA2}

